import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AuthService from './services/AuthService.js';
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />

        {/* Authenticated Routes with Layout */}
        <Route element={<AuthLayout />}>
          <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
          <Route path="/students" element={<ProtectedRoute component={Home} />} />
          {/* Add more protected routes as needed */}
        </Route>

        {/* Redirect any unknown route to Login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
