import axios from 'axios';

const API_URL = 'https://api.svgc.co.in/api';

const AuthService = {
  login: async (username, password) => {
    try {
      const response = await axios.post(API_URL+'/Login', {
        SrNo: username,
        Password: password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error.response || error.message || error);
      throw error; // Re-throw the error after logging
    }
  },

  logout: () => {
    localStorage.removeItem('user');
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },

  isAuthenticated: () => {
    const user = AuthService.getCurrentUser();
    return user && user.token;
  }
};

export default AuthService;
